* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.App {
  font-family: sans-serif;
  /* text-align: center; */
}


